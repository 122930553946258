<template>
  <div
    class="showcase-step"
    :data-step-no="index"
    v-bind:class="{ 'fixed-text-wrapper': index >= 0 }"
  >
    <h3 class="text">{{text}}</h3>
  </div>
</template>

<script>
export default {
  name: "space-showcase-step",
  components: {},
  props: ["index", "text"],
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/base";

.text {
  text-align: center;
}

.fixed-text-wrapper {
  background-color: $dark-color;
  border-radius: 25px;
  opacity: 0.9;
}
</style>
