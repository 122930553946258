<template>
  <div class="feature-item">
    <img :src="imageSrc" :alt="alt">
    <div class="content-wrapper">
      <h4>{{title}}</h4>
      <p v-html="description"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "space-feature-item",
  components: {},
  props: ["imageSrc", "alt", "title", "description"],
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/base";

.feature-item {
  margin: 0 !important;
  padding-bottom: 2rem;

  img {
    display: block;
    padding: auto;
    margin: auto;
    padding-bottom: 1.2rem;
  }

  h4 {
    font-weight: 700;
    color: $light-color;
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 0.875rem;
    color: #c5c5c5;
  }
}

@media only screen and (max-width: $md) {
  .feature-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    height: 100%;
    padding-bottom: 1.2rem;

    img {
      margin: 0;
      padding: 0;
      display: flex;
      align-self: flex-start;
      margin-right: 5%;
    }

    .content-wrapper {
      max-width: 65%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    h4 {
      text-align: left;
      font-size: 0.875rem !important;
      line-height: inherit !important;
    }

    p,
    a {
      text-align: left;
      font-size: 0.75rem;
      line-height: 1.25;
    }
  }
}
</style>
