<template>
  <space-scaffold :showOnlyLogo="true" siteClass="legal" :showOverlay="false">
    <template slot="main">
      <section class="section section-small hero-header" id="legal">
        <b-container>
          <b-row align-self="center" class="hero-1-row">
            <b-col xl="12">
              <div class="hero-title-wrapper">
                <h1>{{page.title}}</h1>
                <h4>
                  <span class="text darken" v-html="page.subtitle"></span>
                </h4>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <section class="legal-section section section-wide light-bg">
        <b-container>
          <b-row>
            <b-col>
              <div v-html="page.content"></div>
            </b-col>
          </b-row>
        </b-container>
      </section>
    </template>
  </space-scaffold>
</template>
<script>
export default {
  name: "legal",
  components: {},
  data() {
    return {};
  },
  computed: {
    page() {
      return this.$store.getters.getPageByUID("legal");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/base";
@import "../assets/styles/_hero-header";
</style>
