<template>
  <footer class="footer">
    <b-container>
      <b-row align-h="center">
        <b-col cols="10" sm="8" md="8" xl="7">
          <ul class="menu menu-light footer-menu">
            <li>
              <router-link to="privacy#privacy">Privacy</router-link>
            </li>
            <li>
              <router-link to="terms#terms">Terms of Use</router-link>
            </li>
            <li>
              <router-link to="legal#legal">Legal Notice</router-link>
            </li>
            <li>
              <router-link to="press">Press Kit</router-link>
            </li>
            <!-- <li>
              <a>Press Kit</a>
            </li>-->
          </ul>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="footer-note">
            A
            dnd product. Brought to you by
            <a
              href="https://twitter.com/johannes_hage/"
              target="_blank"
            >Johannes</a> and
            <a href="https://twitter.com/dominik_scherm" target="_blank">Dominik</a>.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "space-footer",
  components: {},
  props: [],
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/base";
@import "../assets/styles/_menu";

.footer {
  color: #fff;
  background-color: $dark-color;
  padding-top: 5rem;
  padding-bottom: 5rem;
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1);

  .footer-menu {
    margin: auto;
    flex-flow: row wrap;

    li a {
      font-size: 1.4rem;
      font-weight: bold;
    }
  }

  .grey {
    opacity: 0.5;
  }

  .footer-note {
    text-align: center;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}
</style>
