<template>
  <div id="app">
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
import * as types from "./store/mutation-types";

export default {
  name: "app",
  components: {},
  data() {
    return {};
  },
  beforeCreate() {
    this.$store.commit(types.RECEIVE_PAGES, { pages: window.Kirby.pages });
    this.$store.commit(types.RECEIVE_SITE, { site: window.Kirby.site });
  },
  created() {
    window.addEventListener("resize", this.onResize, true);

    this.onResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize, true);
  },
  methods: {
    onResize(event) {
      var windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.getElementsByTagName("body")[0].clientWidth;
      this.$store.commit(types.UPDATE_APP, {
        field: "md",
        value: windowWidth <= 992
      });
      this.$store.commit(types.UPDATE_APP, {
        field: "sm",
        value: windowWidth <= 480
      });
    }
  }
};
</script>

<style lang="scss">
@import "node_modules/reset-css/sass/_reset";
@import "./assets/styles/base";
</style>
