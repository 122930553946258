<template>
  <space-scaffold :showOnlyLogo="true" siteClass="support" :showOverlay="false">
    <template slot="main">
      <section class="section section-small hero-header" id="support">
        <b-container>
          <b-row align-self="center" class="hero-1-row">
            <b-col xl="12">
              <div class="hero-title-wrapper">
                <h1>Press Kit</h1>
                <h4>
                  If you need anything else, just reach out to team@spacebrowser.io. We are here to help!<br>
                  Your Space Team — <b>Johannes &amp; Dominik</b>
                </h4>
                <iframe src="https://drive.google.com/embeddedfolderview?id=1QzFAXUpHYQdMEdvui5EDk3txTS_hZQPP#grid" style="width:100%; height:600px; border:0;"></iframe>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
    </template>
  </space-scaffold>
</template>
<script>
export default {
  name: "support",
  components: {},
  data() {
    return {};
  },
  computed: {
    page() {
      return this.$store.getters.getPageByUID("support");
    }
  },
  created() {
    // window.location.href = "https://google.de";
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/base";
@import "../assets/styles/_hero-header";
</style>
