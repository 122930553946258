import { render, staticRenderFns } from "./SpaceFooter.vue?vue&type=template&id=7389fec0&scoped=true&"
import script from "./SpaceFooter.vue?vue&type=script&lang=js&"
export * from "./SpaceFooter.vue?vue&type=script&lang=js&"
import style0 from "./SpaceFooter.vue?vue&type=style&index=0&id=7389fec0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7389fec0",
  null
  
)

component.options.__file = "SpaceFooter.vue"
export default component.exports