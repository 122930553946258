import Vue from 'vue';
import App from './App.vue';
import BootstrapVue from 'bootstrap-vue';
import VueScrollactive from 'vue-scrollactive';
import VueWaypoint from 'vue-waypoint';
import router from './router/router';
import store from './store';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faYoutube,
  faFacebookF,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { SpaceScaffold, SpaceHeader, SpaceFooter } from './components';

library.add(faYoutube);
library.add(faFacebookF);
library.add(faTwitter);

Vue.use(BootstrapVue);
Vue.use(VueScrollactive);
Vue.use(VueWaypoint);

Vue.component('fa-icon', FontAwesomeIcon);

Vue.component('space-scaffold', SpaceScaffold);
Vue.component('space-header', SpaceHeader);
Vue.component('space-footer', SpaceFooter);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
