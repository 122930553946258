<template>
  <div :class="siteClass">
    <div class="banner">
      <p>
        Space Browser for iPhone has arrived, join our exclusive alpha tester program now.
        <a
          href="https://mailchi.mp/spacebrowser.io/iphone-signup"
          target="_blank"
        >Join now</a>
      </p>
    </div>
    <!-- <a href="https://www.producthunt.com/posts/space-browser" target="_blank">
      <div class="banner banner-ph">
        <h3>
          We are live on Product Hunt today.
          <a href="https://www.producthunt.com/posts/space-browser" target="_blank">Check it out now.</a>
        </h3>
      </div>
    </a>-->
    <space-header :showOnlyLogo="showOnlyLogo"></space-header>
    <main class="mainContainer" ref="mainContainer">
      <slot name="main"></slot>
    </main>
    <space-footer></space-footer>
    <div id="overlay-wrapper" v-if="showOverlay">
      <slot name="overlay-content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "space-scaffold",
  components: {},
  props: ["siteClass", "showOnlyLogo", "showOverlay"],
  data() {
    return {};
  },
  computed: {}
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/base";
@import "../assets/styles/_banner";
@import "../assets/styles/_scaffold";
@import "../assets/styles/_overlay";
</style>
