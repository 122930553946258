<template>
  <header class="header">
    <div class="mobile-menu">
      <input id="toggle" type="checkbox" v-if="!showOnlyLogo" />
      <label class="toggle-container" for="toggle" v-if="!showOnlyLogo">
        <span class="button-toggle"></span>
      </label>
      <nav class="nav" v-bind:class="{ 'nav-active': showOnlyLogo }">
        <scrollactive active-class="active" :modifyUrl="false" v-if="!showOnlyLogo">
          <template v-for="(item, index) in navItems">
            <a v-if="index == 2" :href="item.href" :key="index" class="scrollactive-item nav-item">
              <span style="color: #003bff; font-weight: bold">{{item.title}}</span>
            </a>
            <a
              v-else
              :href="item.href"
              :key="index"
              class="scrollactive-item nav-item"
            >{{item.title}}</a>
          </template>
          <template v-if="showOnlyLogo">
            <a href="#start" target="_self" class="scrollactive-item">
              <span class="logo">
                <router-link to="home#start">Space</router-link>
              </span>
            </a>
          </template>
        </scrollactive>
      </nav>
    </div>
    <scrollactive class="expanded" active-class="active" :modifyUrl="false">
      <ul class="menu menu-light">
        <li>
          <a href="/#start">
            <span class="logo">Space</span>
          </a>
        </li>
        <template v-if="!showOnlyLogo" class="menu-items" v-for="(item, index) in navItems">
          <li :key="index">
            <a
              v-if="index == 2"
              :href="item.href"
              :target="item.target"
              :class="{'scrollactive-item': item.scrollactive}"
            >
              <span style="color: #003bff; font-weight: bold">{{item.title}}</span>
            </a>
            <a
              v-else
              :href="item.href"
              :target="item.target"
              :class="{'scrollactive-item': item.scrollactive}"
            >{{item.title}}</a>
          </li>
        </template>
      </ul>
    </scrollactive>
  </header>
</template>

<script>
export default {
  name: "space-header",
  components: {},
  props: ["showOnlyLogo"],
  data() {
    return {
      navItems: [
        {
          title: "Why Space",
          href: this.getBaseURL("why-space"),
          target: "_self",
          scrollactive: true
        },
        {
          title: "Features",
          href: this.getBaseURL("features"),
          target: "_self",
          scrollactive: true
        },
        {
          title: "Get it now",
          href: "https://apps.apple.com/us/app/space-browser/id1441254967?ls=1",
          target: "_blank",
          scrollactive: false
        }
      ]
    };
  },
  methods: {
    getBaseURL(id) {
      return window.location.host + "/#" + id;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/base";
@import "../assets/styles/_menu";
@import "../assets/styles/_mobile-menu";
</style>
