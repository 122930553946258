import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home';
// import Support from '@/views/Support';
import Privacy from '@/views/Privacy';
import Terms from '@/views/Terms';
import Legal from '@/views/Legal';
import Press from '@/views/Press';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    // {
    //   path: '/support',
    //   name: 'support',
    //   component: Support
    // },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms
    },
    {
      path: '/legal',
      name: 'legal',
      component: Legal
    },
    {
      path: '/press',
      name: 'press',
      component: Press
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
});
