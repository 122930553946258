<template>
  <space-scaffold :showOnlyLogo="false" siteClass="home" :showOverlay="showPrivacyOverlay">
    <template slot="main">
      <section class="section section-wide hero-header" id="start">
        <b-container>
          <b-row align-self="center" class="hero-1-row">
            <b-col xl="12">
              <div class="hero-title-wrapper">
                <h1 v-html="page.herotitle"></h1>
                <h4>
                  <span class="text darken" v-html="page.herosubtitle"></span>
                </h4>
              </div>
              <!-- <scrollactive :modifyUrl="false">
                <a
                  href="https://apps.apple.com/us/app/space-browser/id1441254967?ls=1"
                  target="_blank"
                >
                  <b-button class="button primary-btn cta-btn">{{page.ctabtntitle}}</b-button>
                </a>
              </scrollactive> -->
              <div style="position: relative; width: 250px !important; margin: auto !important; margin-top: 2rem; padding: auto !important">
                <a href="https://www.producthunt.com/posts/space-browser?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-space-browser" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=163827&theme=dark" alt="Space Browser - The first mobile browser made for power users. | Product Hunt Embed" style="width: 250px; height: 54px;" width="250px" height="54px" /></a>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col align-self="center">
              <iframe
                src="https://www.youtube-nocookie.com/embed/fGm_Cps7eVc?rel=0&showinfo=0;controls=false"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                id="yt-video"
              ></iframe>
            </b-col>
          </b-row>
          <!-- <b-row class="hero-2-row">
            <b-col class="showcase-outer-wrapper">
              <Scrollama @step-enter="stepEnterHandler" class="showcase" threshold="1">
                <div class="showcase-graphic" slot="graphic" id="showcase-graphic-container">
                  <div class="showcase-img-wrapper" ref="showcaseImgWrapper">
                    <stream src="390c6f5cf98a5646411fad11b76b9d52" preload autoplay loop mute></stream>
                  </div>
                </div>
                <space-showcase-step :text="showcaseSteps[0].text" :key="0" :index="0"></space-showcase-step>
              </Scrollama>
            </b-col>
          </b-row>-->
        </b-container>
      </section>
      <section
        class="rellax section section-wide feature-detail-section dark-bg"
        data-rellax-speed="-1"
        v-waypoint="{ active: true, callback: enterFirstFeatureSection, options: intersectionOptions }"
      >
        <b-container fluid>
          <b-row>
            <b-col
              align-self="center"
              cols="12"
              md="6"
              xl="6"
              class="feature-text-wrapper align-right"
            >
              <div class="rellax hero-title-wrapper" data-rellax-speed="-1">
                <h2
                  class="text light"
                  v-bind:class="{'align-right': !isMobileScreen, 'align-center': isMobileScreen}"
                >{{showcaseSteps[1].title}}</h2>
                <h4
                  class="text light"
                  v-bind:class="{'align-right': !isMobileScreen, 'align-center': isMobileScreen}"
                >{{showcaseSteps[1].text}}</h4>
              </div>
            </b-col>
            <b-col cols="12" md="6" xl="6">
              <div
                class="feature-vid-wrapper showcase-img-wrapper"
                v-bind:class="{'appear-right': showFeatureVid1 }"
              >
                <stream src="abbd990b0a7ae082e542ec29293ed9e1" preload autoplay loop mute></stream>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <section
        class="rellax section section-wide feature-detail-section dark-bg"
        data-rellax-speed="3"
        v-waypoint="{ active: true, callback: enterSecondFeatureSection, options: intersectionOptions }"
      >
        <b-container fluid>
          <b-row>
            <b-col cols="12" md="6" xl="6">
              <div
                class="feature-vid-wrapper showcase-img-wrapper"
                v-bind:class="{'appear-left': showFeatureVid2 }"
              >
                <stream src="099e7fafda7d08d23b49e72e79ce5c4e" preload autoplay loop mute></stream>
              </div>
            </b-col>
            <b-col
              align-self="center"
              cols="12"
              md="6"
              xl="6"
              class="feature-text-wrapper align-left"
            >
              <div class="rellax hero-title-wrapper" data-rellax-speed="-2">
                <h2
                  class="text light"
                  v-bind:class="{'align-left': !isMobileScreen, 'align-center': isMobileScreen}"
                >{{showcaseSteps[2].title}}</h2>
                <h4
                  class="text light"
                  v-bind:class="{'align-left': !isMobileScreen, 'align-center': isMobileScreen}"
                >{{showcaseSteps[2].text}}</h4>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <section class="section section-wide feature-detail-section dark-bg history-hero-section">
        <b-container>
          <b-row align-self="center" class="hero-1-row">
            <b-col cols="12">
              <div class="hero-title-wrapper">
                <h2 class="text light">{{showcaseSteps[3].title}}</h2>
                <h4 class="text light">{{showcaseSteps[3].text}}</h4>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col align-self="center">
              <div class="feature-vid-wrapper showcase-img-wrapper history-vid">
                <stream src="aab36bd69767465a6bbc40a93cbc411f" preload autoplay loop mute></stream>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <section
        class="rellax section section-wide why-space-section light-bg"
        id="why-space"
        data-rellax-speed="4"
        v-waypoint="{ active: true, callback: enterWhySpaceSection, options: intersectionOptions }"
      >
        <b-container>
          <b-row align-h="center">
            <b-col cols="12" md="10" xl="8">
              <div class="hero-title-wrapper">
                <h2
                  class="typewriter-wrapper text dark"
                  v-bind:class="{'animation-typewriter': showTypewriterAnimation}"
                >{{page.whyspacetitle}}</h2>
                <h3
                  class="text"
                  v-bind:class="{'fade-in': showTypewriterAnimation, 'hidden': !showTypewriterAnimation}"
                  v-html="page.whyspacesubtitle"
                ></h3>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <section class="section section-wide features-section dark-bg" id="features">
        <b-container>
          <b-row>
            <b-col align-self="center">
              <div class="feature-title-wrapper">
                <h2 class="text light" v-html="page.featurestitle"></h2>
                <h4>
                  <span class="text darken" v-html="page.featuressubtitle"></span>
                </h4>
              </div>
            </b-col>
          </b-row>
          <b-row class="feature-items-wrapper">
            <b-col cols="12" md="6" lg="4" xl="4" v-for="(feature, index) in features" :key="index">
              <space-feature-item
                :alt="feature.title"
                :imageSrc="feature.imageSrc"
                :title="feature.title"
                :description="feature.description"
              ></space-feature-item>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <section class="cta-section section section-wide light-bg" id="cta">
        <b-container>
          <b-row>
            <b-col align-self="center" align-h="center">
              <div class="hero-title-wrapper">
                <h2 class="text dark" v-html="page.ctatitle"></h2>
                <h4 class="text dark" v-html="page.ctasubtitle"></h4>
              </div>
              <form
                action="https://space.us7.list-manage.com/subscribe/post?u=aad50637e3e1fc6b6c61845e6&amp;id=2d05323728"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                class="validate form"
                target="_blank"
                @submit="checkForm"
                novalidate
              >
                <div id="mc_embed_signup_scroll">
                  <div class="email-input-wrapper">
                    <input
                      type="email"
                      value
                      v-model="email"
                      name="EMAIL"
                      class="email input input-email"
                      id="mce-EMAIL"
                      :placeholder="page.ctaformemailplaceholder"
                      required
                    />
                    <input
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      class="button primary-btn"
                      v-bind:class="{ disabled: !acceptedPrivacy }"
                      :disabled="!acceptedPrivacy"
                      variant="primary"
                    />
                  </div>
                  <div v-if="errorMessage" class="form-error-wrapper">
                    <p>{{errorMessage}}</p>
                  </div>
                  <div class="privacy-confirm-wrapper">
                    <input
                      type="checkbox"
                      name="accepted-privacy"
                      class="checkbox"
                      id="permitted"
                      v-model="acceptedPrivacy"
                    />
                    <label for="permitted" class="side-label">
                      <span @click.prevent="activateCheckbox">{{page.ctaprivacynotice}}</span>
                      <b>
                        <a href="#">
                          <router-link to="privacy#privacy">&nbsp;{{page.ctaprivacypolicytitle}}</router-link>
                        </a>
                      </b>
                    </label>
                  </div>
                  <div style="position: absolute; left: -5000px;" aria-hidden="true">
                    <input
                      type="text"
                      name="b_aad50637e3e1fc6b6c61845e6_2d05323728"
                      tabindex="-1"
                      value
                    />
                  </div>
                </div>
              </form>
            </b-col>
          </b-row>
          <b-row class="footer-notes">
            <b-col>
              <ul
                class="menu menu-dark social-icons"
                v-bind:class="{ 'menu-center': socialIcons.length < 2 }"
              >
                <li v-if="page.twitterurl">
                  <a href="#" @click.prevent="openURL(page.twitterurl)">
                    <fa-icon :icon="[ 'fab', 'twitter' ]" />
                  </a>
                </li>
                <li v-if="page.facebookurl">
                  <a href="#" @click.prevent="openURL(page.facebookurl)">
                    <fa-icon :icon="[ 'fab', 'facebook-f' ]" />
                  </a>
                </li>
                <li v-if="page.yturl">
                  <a href="#" @click.prevent="openURL(page.yturl)">
                    <fa-icon :icon="[ 'fab', 'youtube' ]" />
                  </a>
                </li>
              </ul>
              <p class="text bold copyright" v-html="page.copyright"></p>
            </b-col>
          </b-row>
        </b-container>
      </section>
    </template>
    <template slot="overlay-content" v-if="page.cookiebannertitle">
      <p>
        {{page.cookiebannertitle}}
        <a href="#">
          <router-link :to="page.cookiebannerlink">&nbsp;{{page.cookiebannerlinktitle}}</router-link>
        </a>
      </p>
      <b-button
        @click.prevent="closePrivacyOverlay"
        class="close-btn"
      >{{page.cookiebannerclosebtntitle}}</b-button>
    </template>
  </space-scaffold>
</template>
<script>
import "intersection-observer"; // for cross-browser support
import { SpaceShowcaseStep, SpaceFeatureItem } from "../components";
import Scrollama from "vue-scrollama";
import Rellax from "rellax";

export default {
  name: "home",
  components: {
    Scrollama,
    SpaceShowcaseStep,
    SpaceFeatureItem
  },
  data() {
    return {
      showcaseIsVisible: false,
      showcaseCurrentSectionScrollY: [],
      showcaseCurrentSection: 0,
      showcaseImgScaleFactor: 1,
      showcaseImgTransformX: 0,
      showcaseImgTransformY: 0,
      showcaseVirtualScroll: 0,
      showcaseScrollDirection: "down",
      scrollJumpHeight: 0,
      scrollPageY: 0,
      email: null,
      acceptedPrivacy: false,
      errorMessage: null,
      showPrivacyOverlay: true,
      showTypewriterAnimation: false,
      intersectionOptions: {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        thresholds: [0, 100]
      },
      showFeatureVid1: false,
      showFeatureVid2: false
    };
  },
  computed: {
    page() {
      return this.$store.getters.getPageByUID("home");
    },
    features() {
      const page = this.$store.getters.getPageByUID("home");
      const featuresRaw = page.features.split("\n");
      let features = [];
      let id = 0;
      for (let i = 0; i < featuresRaw.length; i++) {
        if (i % 4 === 0) {
          const imageNameRaw = featuresRaw[i];
          const imageName = imageNameRaw.substring(
            imageNameRaw.lastIndexOf(" ") + 1,
            imageNameRaw.lastIndexOf(".")
          );
          let imageSrc = "";
          const { files } = page;
          for (const file of files) {
            if (file.name == imageName) {
              imageSrc = file.url;
            }
          }
          const feature = {
            id,
            imageSrc,
            title: featuresRaw[i + 1],
            description: featuresRaw[i + 2]
          };
          id++;
          features.push(feature);
        }
      }
      return features;
    },
    showcaseSteps() {
      const page = this.$store.getters.getPageByUID("home");
      const stepsRaw = page.scrollsteps.split("\n");
      let steps = [];
      let id = 0;
      for (let i = 0; i < stepsRaw.length; i++) {
        if (i % 4 === 0) {
          const imageNameRaw = stepsRaw[i];
          const imageName = imageNameRaw.substring(
            imageNameRaw.lastIndexOf(" ") + 1,
            imageNameRaw.lastIndexOf(".")
          );
          let imageSrc = "";
          const { files } = page;
          for (const file of files) {
            if (file.name == imageName) {
              imageSrc = file.url;
            }
          }
          const step = {
            id,
            imageSrc,
            title: stepsRaw[i + 1],
            text: stepsRaw[i + 2]
          };
          id++;
          steps.push(step);
        }
      }
      return steps;
    },
    socialIcons() {
      const page = this.$store.getters.getPageByUID("home");
      const facebookURL = page.facebookurl;
      const twitterURL = page.twitterurl;
      const ytURL = page.yturl;

      const links = [facebookURL, twitterURL, ytURL];
      const socialIcons = [];

      for (const link of links) {
        if (link != null && link != "") {
          socialIcons.push(link);
        }
      }
      return socialIcons;
    },
    isMobileScreen() {
      return window.innerWidth < 820;
    }
  },
  methods: {
    handleScroll(event) {
      this.scrollJumpHeight = event.pageY - this.scrollPageY;
      if (event.scrollPageY <= this.scrollPageY) {
        this.showcaseScrollDirection = "up";
      }

      this.scrollPageY = event.pageY;

      if (this.showcaseIsVisible) {
        const scrollY = window.scrollY;
        if (this.showcaseCurrentSection == 0) {
          this.showcaseImgTransformY = scrollY / 60 + 22.5;
          if (window.innerWidth > 992) {
            this.showcaseImgTransformY = scrollY / 45 + 10;
          }
          if (window.innerWidth <= 480) {
            this.showcaseImgTransformY = scrollY / 60 + 36.5;
          }

          this.showcaseImgScaleFactor = 1 + scrollY / 2750;
          const maxWidthFactor =
            window.innerWidth / this.$refs.showcaseImgWrapper.clientWidth;
          if (this.showcaseImgScaleFactor > maxWidthFactor) {
            this.showcaseImgScaleFactor = maxWidthFactor;
          }
          this.showcaseImgTransformX = 0;
        } else {
          // if (this.showcaseScrollDirection == "down") {
          //   if (
          //     this.showcaseVirtualScroll > -80 &&
          //     this.showcaseVirtualScroll <= 0
          //   ) {
          //     this.showcaseVirtualScroll -= 5 / this.scrollJumpHeight;
          //   }
          // } else if (this.showcaseScrollDirection == "up") {
          //   if (
          //     this.showcaseVirtualScroll >= -80 &&
          //     this.showcaseVirtualScroll < 0
          //   ) {
          //     this.showcaseVirtualScroll += 5 / this.scrollJumpHeight;
          //   }
          // }
          // console.log(this.showcaseVirtualScroll);
          const firstStepContainer = document.getElementById("showcase-step-0");
          const secondStepContainer = document.getElementById(
            "showcase-step-1"
          );
          const thirdStepContainer = document.getElementById("showcase-step-2");
          const heights =
            firstStepContainer.scrollHeight + secondStepContainer.scrollHeight;
          if (this.showcaseCurrentSection == 1) {
            this.showcaseImgTransformX = (heights - event.pageY) * 0.05;
          } else if (this.showcaseCurrentSection == 2) {
            this.showcaseImgTransformX = (heights - event.pageY) * -0.025;
          }
          // this.showcaseImgTransformX =
          //   (scrollY - this.showcaseCurrentSectionScrollY) * (4 / 50);
        }
        this.$refs.showcaseImgWrapper.style.transform = `translate3d(${this.showcaseImgTransformX}%, ${this.showcaseImgTransformY}%, 0) scale(${this.showcaseImgScaleFactor})`;
      }
    },
    stepEnterHandler({ element, index, direction }) {
      this.showcaseIsVisible = true;
      this.showcaseCurrentSection = index;
      this.showcaseScrollDirection = direction;
      if (direction == "down") {
        // this.showcaseVirtualScroll = 0;
        this.showcaseCurrentSectionScrollY[index] = window.scrollY;
      }
    },
    enterFirstFeatureSection({ going, direction }) {
      // going: in, out
      // direction: top, right, bottom, left
      if (going === this.$waypointMap.GOING_IN) {
        this.showFeatureVid1 = true;
      }
    },
    enterSecondFeatureSection({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        this.showFeatureVid2 = true;
      }
    },
    enterWhySpaceSection({ going, direction }) {
      if (going === this.$waypointMap.GOING_IN) {
        this.showTypewriterAnimation = true;
      }
    },
    checkForm(e) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.email && re.test(String(this.email).toLowerCase())) {
        this.errorMessage = null;
        return true;
      }
      this.errorMessage =
        "It seems like your email is not valid ☹️. Please double check your input.";
      e.preventDefault();
    },
    activateCheckbox(e) {
      this.acceptedPrivacy = !this.acceptedPrivacy;
    },
    openURL(url) {
      if (url && url !== "#") {
        window.open(url, "_blank");
      }
    },
    closePrivacyOverlay() {
      const overlay = document.getElementById("overlay-wrapper");
      overlay.style.display = "none";
      localStorage.setItem("hide-privacy-modal", JSON.stringify(true));
    }
  },
  mounted() {
    const scrollamaGraphicContainer = document.getElementsByClassName(
      "scrollama-graphic"
    )[0];
    scrollamaGraphicContainer.style.zIndex = "100";

    const scrollamaStepsContainer = document.getElementsByClassName(
      "scrollama-steps"
    )[0];
    scrollamaStepsContainer.style.zIndex = "0";

    if (localStorage.getItem("hide-privacy-modal")) {
      this.showPrivacyOverlay = false;
    }

    if (!this.isMobileScreen) {
      var rellax = new Rellax(".rellax", { center: true });
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
<style src="vue-scrollama/dist/vue-scrollama.css"></style>
<style lang="scss" scoped>
@import "../assets/styles/base";
@import "../assets/styles/_menu";
@import "../assets/styles/_hero-header";
@import "../assets/styles/_checkbox";
@import "../assets/styles/_animation-typewriter";

#yt-video {
  position: relative;
  width: 100%;
  left: 0;
  margin-top: 5rem;
  height: 30rem;
}

#start {
  padding-bottom: 4rem !important;
}

.showcase {
  .showcase-graphic {
    width: 90%;
    margin: auto;
    padding: auto;
  }

  .showcase-step {
    padding: 32vh 0;
    margin: 0 auto 60vh;
    color: $light-color;
  }

  .showcase-img-wrapper {
    transform: translate3d(0, 10%, 0);
  }
}

.showcase-img-wrapper {
  position: relative;
  background-image: url("../assets/ipad-pro-frame.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 27px 25px;

  .showcase-img {
    width: 100%;
  }

  .overlay-video {
    position: relative;
    z-index: 200;
    width: 100%;
    border-radius: 5px;
  }

  .video-fade-in {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
    -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
    animation: fadein 1s;
  }

  .hero-image-fallback {
    box-sizing: border-box;
    position: absolute;
    z-index: $z-layer-2;
    background-image: url("../assets/hero-image.jpg") !important;
    width: calc(100% - 54px);
    height: calc(100% - 50px);
  }
}

.why-space-section {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.feature-detail-section {
  overflow-x: hidden;
  .feature-text-wrapper {
    padding-right: 3%;
    padding-left: 3%;

    &.align-left {
      padding-right: 15%;
    }

    &.align-right {
      padding-left: 15%;
    }
  }

  /*
  .feature-vid-wrapper {
    &.align-left {
      margin-left: -45%;
    }

    &.align-right {
      margin-right: -45%;
    }
  }
  */

  .feature-vid-wrapper {
    width: 100%;

    &.appear-left {
      margin-left: -500px;
      -webkit-animation: slide 0.5s forwards;
      animation: slide 0.5s forwards;
    }

    &.appear-right {
      margin-left: 500px;
      -webkit-animation: slide 0.5s forwards;
      animation: slide 0.5s forwards;
    }

    @-webkit-keyframes slide {
      100% {
        margin-left: 0;
      }
    }

    @keyframes slide {
      100% {
        margin-left: 0;
      }
    }
  }
}

.features-section {
  padding-top: 0rem;

  .feature-title-wrapper {
    color: $light-color;
    text-align: center;
  }

  .feature-items-wrapper {
    margin-top: 5rem;
  }
}

.history-hero-section {
  .history-vid {
    margin: auto;
    margin-top: 4rem;
    width: 80%;
  }
}

.cta-section {
  padding-bottom: 3rem !important;

  h4 {
    display: block;
    margin-top: 2rem;
  }

  .privacy-confirm-wrapper,
  .email-input-wrapper {
    display: flex;
    flex-direction: row;
    max-width: 40%;
    align-self: center;
    justify-content: center;
    margin: auto;
  }

  .privacy-confirm-wrapper {
    margin-top: 1rem;
  }

  .email-input-wrapper {
    margin-top: 3rem;

    .input {
      width: 65%;
      padding-left: 1rem;
      padding-right: 2rem;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      &:focus {
        outline: none !important;
      }
    }

    .button {
      width: 35%;
      margin-left: -1.25rem;
      border-radius: 5px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .form-error-wrapper {
    margin-top: 1rem;

    p {
      color: red;
      text-align: center;
    }
  }

  .footer-notes {
    margin-top: 10rem;

    .social-icons {
      width: 10%;
      margin: auto;
      padding: auto;

      li {
        opacity: 0.4;
      }
    }

    .copyright {
      opacity: 0.4;
      font-size: 0.75rem;
      font-weight: bold;
      text-align: center;
    }
  }
}

@media screen and (max-width: $lg) {
  .showcase-img-wrapper {
    background-image: none;
    padding: 0;
    height: 100%;

    .hero-image-fallback {
      width: 100%;
      height: 98%;
    }
  }
}

@media only screen and (max-width: $md) {
  #yt-video {
    margin-top: 2rem;
    width: 100%;
    left: 0%;
    height: 15rem;
  }

  .showcase {
    .showcase-step {
      margin: 0 auto 20vh;
    }

    .showcase-img-wrapper {
      transform: translate3d(0, 22.5%, 0);
    }
  }

  .cta-section {
    .email-input-wrapper {
      max-width: 75%;
    }

    .privacy-confirm-wrapper {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: $sm) {
  .showcase {
    .showcase-step {
      margin: 0 auto 5vh;
    }

    .showcase-img-wrapper {
      transform: translate3d(0, 36.5%, 0);
    }
  }

  .feature-detail-section {
    .feature-text-wrapper {
      &.align-left {
        padding-left: 2.5%;
        padding-right: 2.5%;
        padding-top: 8%;
      }

      &.align-right {
        padding-left: 2.5%;
        padding-right: 2.5%;
        padding-bottom: 8%;
      }
    }
  }

  .history-hero-section {
    .history-vid {
      margin-top: 2rem;
      width: 100%;
    }
  }

  .why-space-section {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .cta-section {
    .email-input-wrapper {
      max-width: 100%;
    }
  }
}
</style>
